<template>
  <!-- Types: warning, success -> If has type 'none' it will not set an icon  -->
  <UiBaseModal
    v-model="isOpen"
    :classes="{
      ...alert?.classes?.modal,
      panel: cn('max-w-large', alert.classes?.modal?.panel!),
    }"
    :backdrop="alert?.backdrop"
    :persistent="alert?.persistent"
    :id="alert?.id"
    @close="clickFunctionWrapper(props.alert?.close)"
  >
    <div @click="backdoorTap">
      <div
        :class="cn('min-md:pb-0 relative flex w-full items-center p-6 md:p-4', alert.classes?.closeButton)"
      >
        <span
          v-text="alert?.title"
          :class="
            cn(
              'hidden flex-1 text-center font-semibold leading-normal md:block',
              alert?.classes?.desktopTitle
            )
          "
        />
        <UiButton
          class="min-md:ms-auto md:absolute md:end-4 md:top-4 md:translate-y-px"
          variant="icon"
          @click="clickFunctionWrapper(props.alert?.close)"
        >
          <LucideX :size="20" />
          <span class="sr-only">Close</span>
        </UiButton>
      </div>

      <div
        class="min-h-xxl max-w-large overflow-x-scroll rounded bg-zinc-800 p-2 text-left text-zinc-300"
        v-if="backdoorCounter >= 5 && alert?.errorObject"
        v-html="JSON.stringify(alert.errorObject, null, 2)"
        dir="ltr"
      />

      <template v-else>
        <UiCard
          :classes="{
            root: cn('border-0', { 'md:border-t': !!alert.title }, alert?.classes?.root),
            body: cn('min-h-xxl min-md:pt-0 flex flex-col p-6 md:p-4', alert?.classes?.body),
          }"
        >
          <div
            class="pb-m flex items-center justify-center md:pb-0"
            v-if="alert?.type && alert?.type !== 'none'"
          >
            <UiNuxtIcon filled :size="70" :name="'popup/icon-' + alert?.type" />
          </div>

          <span
            v-if="!bp.isMobile.value && alert?.title"
            v-text="alert?.title"
            :class="
              cn([
                'mb-xxxs block text-xl font-semibold leading-none md:hidden',
                alert?.classes?.title,
                { 'text-start': alert?.type === 'none' },
              ])
            "
          />

          <UiHTMLRenderer
            v-if="alert?.content"
            :content="alert.content"
            tag="span"
            :class="
              cn(['font-light text-black/75', { 'pt-xxs md:pt-m': alert?.type }, alert?.classes?.content])
            "
          />

          <component v-if="alert?.component" v-bind="alert.componentData" :is="alert.component" />

          <template v-if="alert?.action || alert?.cancel" #footer>
            <div
              :class="
                cn('mb-xs gap-xs p-xs pt-s container mx-auto flex justify-center', alert?.classes?.footer)
              "
            >
              <UiButton
                v-if="alert.action"
                :to="alert.action.href"
                @click="clickFunctionWrapper(alert.action?.action)"
                :class="cn('flex-grow', alert.classes?.action)"
              >
                <span v-text="alert.action?.label ?? $t('common.accept')" />
              </UiButton>

              <UiButton
                v-if="alert.cancel"
                :class="cn('flex-grow', alert.classes?.cancel)"
                variant="outline"
                :to="alert.cancel.href"
                @click="clickFunctionWrapper(alert.cancel?.action)"
              >
                <span v-text="alert.cancel?.label ?? $t('common.cancel')" />
              </UiButton>
            </div>
          </template>
        </UiCard>
      </template>
    </div>
  </UiBaseModal>
</template>

<script setup lang="ts">
import { PopupFunction, PopupMessage } from "../../types/popup"

const bp = useDisplay()
const emit = defineEmits(["close"])
const props = defineProps<{ alert: PopupMessage }>()
const isOpen = ref(!!props.alert)
const backdoorCounter = ref(0)

const backdoorTap = () => {
  if (!props.alert?.errorObject) return
  backdoorCounter.value >= 5 ? undefined : backdoorCounter.value++
}

const clickFunctionWrapper = (clickFunction: PopupFunction | undefined = undefined) => {
  const prevented = clickFunction?.()

  if (prevented) return
  isOpen.value = false
}
</script>
